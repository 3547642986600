/*-- ===============================================

Product: 'Cynic - illustration version for digital agencies'
Version: 3.0.0 
Author: axilweb
Date Created: July 11, 2018

==================================================== --*/

/*-- == Table of contents =====================

( Example: Type 'ctrl+f' and then '1.example' to jump to the
 "example" section. Click 'find' if needed.)
 
1. Base css
2. Form css
3. Header css
4. Banner css
5. Achievements css
6. Services css
7. Features css
8. Projects css
9. Team css
10. Clients css
11. Pricing style
12. Newsletter style
13. News css
14. Reviews css
15. Location css
16. Footer css


================================================== --*/

/* -- Imported Fonts
-------------------------------------- -- */

@import url('https://fonts.googleapis.com/css?family=Catamaran:300,400,500,600,700,800,900|Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');
// Sass Files to Import
//Common files
@import 'defaults/variables';
@import 'components/mixins';
@import 'defaults/default';
@import 'defaults/base';
@import 'components/form';
@import 'components/header';
@import 'components/banner';
@import 'components/widgets';
@import 'components/post-metas';
@import 'components/achievements';
@import 'components/services';
@import 'components/features';
@import 'components/projects';
@import 'components/team';
@import 'components/clients';
@import 'components/pricing';
@import 'components/newsletter';
@import 'components/comments';
@import 'components/news';
@import 'components/news-details';
@import 'components/reviews';
@import 'components/location';
@import 'components/process';
@import 'components/case-study';
@import 'components/footer';
@import 'components/responsive';
//Browser fixes
@import 'components/browserhack';