/* -- Responsive styling
------------------------------------------- -- */

@media (max-width: 1199px) {

    /* -- services css -- */
    .service-block {
        margin-bottom: 3rem;
    }

    .grid-wrapper [class*="col-"]:last-child .service-block {
        margin-bottom: 0;
    }

    .navbar-nav li {
        margin-left: .5rem;
    }
}

@media (max-width: 991px) {

    /* -- common css -- */
    .section-gap {
        padding: 4.5rem 0;
    }

    br {
        display: none;
    }

    /* -- header css -- */
    .navbar-nav li {
        width: 100%;
        margin: 0;

        a:not(.custom-btn) {
            padding: 1rem 0;
            border-radius: 6px;

            &::before {
                display: none;
            }

            &:hover,
            &.active {
                color: $color-palette-b;
            }
        }
    }

    header .navbar-nav {
        background-color: #eee;
        padding: 2.1rem 1.5rem;
        text-align: center;
    }

    .navbar-nav li .custom-btn {
        width: 100%;
        margin: 1.8rem auto;
    }

    /* -- banner css -- */
    .banner {
        height: auto;
        padding-top: 13.3rem;
        padding-bottom: 3rem;
    }

    .banner .img-container {
        padding: 3rem;
    }

    .banner img {
        padding: 0;
    }

    /* -- achievements css -- */
    .counter-wrapper .number {
        font-size: 3rem;
        line-height: 1;
    }

    .counter-wrapper {
        font-size: 1.6rem;
        margin-top: 5px;
    }

    .counter-block .icon-container {
        margin-right: 1rem;
    }

    .onepage-services .floating-service-wrapper {
        transform: translateY(-60px);
    }

    .achievements {
        padding-bottom: 10.5rem;
    }

    .features-grid {
        padding-bottom: 5.2rem;
    }

    .features-grid img {
        max-width: 40rem;
        margin-bottom: 2.1rem;
    }

    .section-title {
        margin: .8rem 0 1rem;
    }

    .grid-wrapper {
        padding-top: 2.1rem;
    }
}

@media (max-width: 767px) {
    body {
        font-size: 1.4rem;
        line-height: 2.6rem;
    }

    h1 {
        font-size: 3rem;
        line-height: 3.6rem;
        margin: .3rem 0 .7rem;
    }

    h2 {
        font-size: 2.4rem;
        line-height: 3.4rem;
    }

    h3 {
        font-size: 2rem;
        line-height: 3rem;
    }

    h4 {
        font-size: 1.8rem;
        line-height: 2.8rem;
    }

    p.larger-txt {
        font-size: 1.6rem;
        line-height: 2.6rem;
    }

    .navbar.scrolled {
        margin-top: 1.5rem;
    }

    .banner p {
        margin-bottom: 2.2rem;
    }

    .custom-btn {
        padding: 1rem 2rem;
    }

    .counter-block {
        display: block !important;
        text-align: center;
    }

    .counter-block .icon-container {
        margin-right: 0;
        margin-bottom: 2rem;

        img {
            max-height: 4rem;
        }
    }

    .counter-wrapper {
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .counter-wrapper .number {
        font-size: 2rem;
    }

    .scrolled .navbar-nav {
        margin-bottom: 3rem;
    }

    .service-block img {
        max-height: 5rem;
        margin-bottom: 2rem;
    }

    .service-block h4 {
        margin-bottom: 1.2rem;
    }

    .features-grid img {
        max-width: 100%;
    }

    .hyperlink {
        font-size: 1.2rem;
    }

    .hyperlink::after {
        left: 0;
    }

    .features-content .hyperlink {
        margin-top: 1.1rem;
    }

    .counter-wrapper br {
        display: block;
    }

    .clients {
        padding: 4rem 0;
    }

    .img-card h4 span {
        font-size: 1.8rem;
        line-height: 2.8rem;
    }

    .img-card h4 {
        font-size: 1.4rem;
    }

    .img-card>img {
        width: 100%;
    }

    .pricing-block {
        border-right: none;
        border-bottom: 2px solid $border-color;
    }

    .newsletter-form {
        text-align: center;

        input {
            margin-bottom: 1rem;
        }

        .custom-btn {
            display: block;
        }
    }

    .pricing-grid-wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .contact-info a:not(.custom-btn) {
        font-size: 1.4rem;
        line-height: 2.6rem;
    }

    .contact-info .custom-btn {
        margin-top: .6rem;
    }

    .page-footer {
        padding: 3.6rem 0 3.2rem;
    }

    .nav-tabs .nav-link {
        padding: 1rem .5rem 1.1rem;
    }

    .modal .modal-content {
        padding: 3rem 1.5rem 1.5rem;
    }

    .modal .modal-content .close {
        top: 1rem;
        right: 1rem;
    }

    .blog-info li:not(:last-child) {
        margin-right: 1rem;
    }

    .blog-info li,
    .blog-info a {
        font-size: 1.3rem;
    }

    .blog-info li img,
    .blog-info a img {
        max-height: 1.1rem;
    }

    .common-list li::after {
        top: .8rem;
    }

    .clients-carousel-wrapper .owl-stage .item img {
        width: 100%;
        max-width: 10rem;
    }

    .owl-carousel .owl-item .item {
        padding: 0 1.5rem;
    }

    .service-block .service-overlay {
        padding: 1rem 0;
    }

    .facilities>div {
        margin-bottom: 0;
    }

    .feature-video-popup {
        width: 6rem;
        height: 6rem;
    }

    .feature-video-popup::after {
        border-top-width: 5px;
        border-left-width: 10px;
        border-bottom-width: 5px;
        margin-left: 2px;
    }

    .full-width-modal.quote-modal .modal-content {
        padding-top: 39.5px;
        padding-bottom: 19.5px;
    }

    .pricing-grid-wrapper [class*="col-"]:first-child .pricing-group {
        margin-right: 0;
    }

}

@media (max-width: 575px) {
    .onepage-services {
        padding: 0 15px;
    }
}